import React, { useState } from "react";
import tw, { styled, css } from "twin.macro";

const Video = styled.video(({ filterStrength }) => [
  tw`absolute top-0 left-0 object-cover w-full h-full z-behind`,
  css`
    filter: brightness(${100 - filterStrength}%);
  `,
]);
const Overlay = styled.div(({ hide }) => [
  tw`absolute top-0 bottom-0 left-0 right-0 transition-all duration-500 ease-in-out bg-black opacity-100 z-behind`,
  hide === true && tw`opacity-0`,
]);

const VideoBanner = ({ visible, ...rest }) => {
  console.log(visible);
  const [isLoaded, setLoaded] = useState(false);
  return (
    <>
      {visible && (
        <Video
          autoPlay
          muted
          loop
          playsInline
          {...rest}
          onPlay={() => {
            setLoaded(true);
          }}
        />
      )}
      <Overlay hide={visible && isLoaded} />
    </>
  );
};

export default VideoBanner;
