import React from "react";
import tw, { styled } from "twin.macro";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Parallax } from "react-scroll-parallax";

const ParallaxGalleryStyles = styled.div`
  ${tw`relative w-full mx-8 overflow-hidden`}
  height: 52vw;
`;

const Wrapper = styled.div`
  ${tw`absolute`}
  left: 50%;
  top: 50%;
  height: calc(52vw * 1.8);
  width: 150%;
  transform: translate3d(-50%, -50%, 0) rotate(22.5deg);
`;

const Row = styled.div`
  ${tw`flex w-full`}
`;

const Image = styled.div`
  ${tw`relative flex-none`}
  --tile-margin: 1vw;
  width: calc(16.6666% - var(--tile-margin) * 2);
  height: calc(100vw / 5 - (4 * var(--tile-margin) / 2));
  margin: var(--tile-margin);

  .image {
    ${tw`top-0 bottom-0 left-0 right-0 w-full h-full`}
    object-position: 50% 50%;
  }
`;

const ParallaxGallery = ({ images, speed, ...rest }) => {
  let imagesMap = [
    [false, false, false, true, true, true],
    [false, true, true, true, true, true],
    [true, true, true, true, true, true],
    [true, true, true, true, true, false],
    [false, true, true, true, false, false],
  ];
  let i = 0;
  imagesMap = imagesMap.map((row) =>
    row.map((show) => {
      if (show === true) i++;
      else return null;
      return images[i - 1];
    })
  );
  const offset = [speed, -speed];
  return (
    <ParallaxGalleryStyles {...rest} id="gallery">
      <Wrapper>
        {imagesMap.map((row, i) => (
          <Parallax x={i % 2 === 0 ? offset : offset.map((n) => n * -1)}>
            <Row key={i}>
              {row.map((image, j) => (
                <Image key={j}>
                  {image !== null && (
                    <GatsbyImage
                      className="image"
                      image={getImage(image)}
                      alt="Test"
                    />
                  )}
                </Image>
              ))}
            </Row>
          </Parallax>
        ))}
      </Wrapper>
    </ParallaxGalleryStyles>
  );
};

export default ParallaxGallery;
