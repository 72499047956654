import React, { useState } from "react";
import { graphql, StaticQuery } from "gatsby";
import { Hero, Buttons } from "./styles";
import IntroSequence from "@/IntroSequence";
import Button from "@/Button";
import VideoBanner from "@/VideoBanner";
import DesktopNavigation from "Partials/DesktopNavigation";
import MobileNavigation from "Partials/MobileNavigation";
import "twin.macro";

const FrontHero = () => {
  const [introComplete, setComplete] = useState(false);
  return (
    <StaticQuery
      query={graphql`
        query {
          wp {
            acfOptionsFrontHero {
              frontHero {
                titleSequence {
                  text
                  boldedWords {
                    word
                  }
                }
                sequenceDuration
                bannerVideo {
                  src: mediaItemUrl
                }
                bannerFilterStrength
                buttons {
                  button {
                    url
                    title
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        const { titleSequence, sequenceDuration, bannerVideo, ...fields } =
          data.wp.acfOptionsFrontHero.frontHero;
        return (
          <>
            <DesktopNavigation visible={introComplete} />
            <MobileNavigation visible={introComplete} />
            <Hero>
              <IntroSequence
                sequence={titleSequence}
                duration={sequenceDuration}
                onComplete={() => setComplete(true)}
              />
              <VideoBanner
                visible={introComplete}
                src={bannerVideo.src}
                filterStrength={fields.bannerFilterStrength}
              />
              <Buttons visible={introComplete}>
                {fields.buttons.map(({ button }, i) => (
                  <Button
                    tw="sm:mx-8 my-2"
                    to={button.link}
                    key={i}
                    dark={i === 1}
                  >
                    {button.title}
                  </Button>
                ))}
              </Buttons>
            </Hero>
          </>
        );
      }}
    />
  );
};

export default FrontHero;
