import tw, { styled, css } from "twin.macro";
import { keyframes } from "styled-components";

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(5rem);
  }

  to {
    opacity: 1;
    transform: none;
  }
`;

export const Hero = styled.section`
  ${tw`relative flex flex-col items-center justify-center w-full max-w-full min-h-screen overflow-hidden text-center text-gray`}
`;

export const Buttons = styled.div(({ visible }) => [
  tw`absolute flex-wrap items-center justify-center w-full -my-2 opacity-0 sm:-mx-8`,
  css`
    top: 70%;
    transform: translateY(-50%);
  `,
  visible &&
    css`
      animation: ${fadeIn} 1s ease-in-out forwards;
    `,
]);
