import React from "react";
import { graphql, StaticQuery } from "gatsby";
import { Section, Left, Right } from "./styles";
import Title from "@/Title";
import Review from "@/AnimatedReview";
import "twin.macro";

const Reviews = () => (
  <StaticQuery
    query={graphql`
      query {
        wp {
          acfOptionsReviews {
            reviews {
              supertext: reviewsSupertext
              title: reviewsTitle
              description: reviewsDescription
              reviews {
                review
                thumbnail {
                  ...Image
                }
                embed
              }
              button: reviewsButton {
                title
                url
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const { reviews: fields } = data.wp.acfOptionsReviews;
      return (
        <Section>
          <Left>
            <Title
              title={fields.title}
              supertext={fields.supertext}
              description={fields.description}
              hc
            />
            <Review review={fields.reviews[0]} />
          </Left>
          <Right>
            <Review review={fields.reviews[1]} tw="mb-16" />
            <Review review={fields.reviews[2]} />
          </Right>
        </Section>
      );
    }}
  />
);

export default Reviews;
