import React, { useEffect } from "react";
import tw, { styled, css } from "twin.macro";
import { useStaticQuery, graphql } from "gatsby";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import ModalContext from "@/ModalContext";
import Content from "@/Content";
import { useInView } from "react-intersection-observer";
import anime from "animejs";

const Container = styled.div`
  ${tw`flex flex-col items-center justify-center w-full`}
  transform: translateY(300px);
  opacity: 0;

  .logo {
    ${tw`mt-8`}
  }
`;

const Thumbnail = styled.div`
  ${tw`relative flex items-center justify-center w-full cursor-pointer`}

  .gatsby-image-wrapper {
    ${tw`transition-all duration-300 ease-in-out shadow-lg`}
  }

  .play-button {
    ${tw`absolute!`}
    max-width: 150px;
    width: 25%;
  }

  &:hover {
    .thumbnail {
      filter: brightness(0.6) grayscale(0.4);
      transform: scale(0.95);
    }

    .play-button {
      transform: scale(1.05);
    }
  }
`;

const AnimatedReview = ({ review, ...rest }) => {
  const { wp } = useStaticQuery(graphql`
    query {
      wp {
        esquireAcf {
          siteSettings {
            playButton {
              ...Image
            }
          }
        }
      }
    }
  `);
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.225,
  });
  const thumbnailRef = React.useRef();
  useEffect(() => {
    if (inView) {
      anime({
        targets: thumbnailRef.current,
        translateY: [300, 0],
        opacity: ["0%", "100%"],
        scale: ["0.9", "1"],
        duration: 1500,
        easing: "easeOutCirc",
      });
    }
  });
  return (
    <Container {...rest} ref={thumbnailRef}>
      <ModalContext.Consumer>
        {(modal) => (
          <Thumbnail
            ref={ref}
            onClick={() => {
              modal.openModal(review.embed);
            }}
          >
            <GatsbyImage
              image={getImage(review.thumbnail.localFile.childImageSharp)}
              alt={review.thumbnail.altText}
              className="thumbnail"
            />
            <GatsbyImage
              className="play-button"
              image={getImage(wp.esquireAcf.siteSettings.playButton.localFile)}
              alt="Play button"
            />
          </Thumbnail>
        )}
      </ModalContext.Consumer>
      <Content
        p
        tw="mt-12 text-center"
        css={css`
          max-width: 425px;
        `}
      >
        {"“" + review.review + "”"}
      </Content>
    </Container>
  );
};

export default AnimatedReview;
