import React from "react";
import { graphql, StaticQuery } from "gatsby";
import { Item, Section, Services } from "./styles";
import Title from "@/Title";
import Service from "@/Service";
import "twin.macro";

const services = () => (
  <StaticQuery
    query={graphql`
      query {
        wp {
          acfOptionsServices {
            services {
              supertext: servicesSupertext
              title: servicesTitle
              services {
                title
                description
                listTitle
                list {
                  title
                }
                button
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const { services: fields } = data.wp.acfOptionsServices;
      return (
        <Section>
          <Title
            title={fields.title}
            supertext={fields.supertext}
            hc
            tw="px-4 sm:px-8 xl:px-32"
          />
          <Services>
            {fields.services.map((service, i) => (
              <Item key={i} hideBorder={i === 0}>
                <Service service={service} number={i + 1} />
              </Item>
            ))}
          </Services>
        </Section>
      );
    }}
  />
);

export default services;
