import React from "react";
import tw, { styled, css } from "twin.macro";
import { leadingZero, getRelativeURL } from "#/functions";
import Content from "@/Content";
import { Link } from "gatsby";

const ServiceStyles = styled.div(({ hideBorder, number }) => [
  tw`relative flex flex-col items-center justify-center w-full h-full px-8 pt-24 pb-32 text-center shadow-lg sm:shadow-none`,
  !hideBorder &&
    css`
      &:after {
        ${tw`w-2px bg-divider absolute z-behind left-0 h-full content[''] top-0`}
      }
    `,
  number === 2 &&
    css`
      .button {
        ${tw`bg-palette-2 hover:bg-palette-3`}
      }
    `,
  number === 3 &&
    css`
      .button {
        ${tw`bg-palette-3 hover:bg-palette-4`}
      }
    `,
  number > 3 &&
    css`
      .button {
        ${tw`bg-palette-4 hover:bg-palette-5`}
      }
    `,
]);
const Number = tw.p`text-2xl sm:text-4xl font-bold text-primary leading-none mb-4`;
const Title = tw(Number)`text-text mb-6 sm:mb-16`;
const Blue = tw(Number)`text-xl sm:text-3xl  mb-4 sm:mb-6`;
const Divider = styled.div`
  ${tw`my-6 sm:my-12 bg-divider h-2px`}
  width: 80%;
`;

const List = styled.ul`
  ${tw`flex flex-col items-center justify-center w-full -my-2 list-none`}
`;

const Item = tw.li`py-2`;

const Button = tw(
  Link
)`bg-palette-1 absolute bottom-0 left-0 font-bold text-sm text-white tracking-widest transition-all duration-500 py-8 w-full px-4 text-center ease-in-out hover:bg-palette-2`;

const Service = ({ number, service, ...rest }) => (
  <ServiceStyles {...rest} number={number}>
    <Number>{leadingZero(number)}.</Number>
    <Title>{service.title}</Title>
    <Blue>Overview</Blue>
    <Content>{service.description}</Content>
    <Divider />
    <Blue>{service.listTitle}</Blue>
    <List>
      {service.list.map(({ title }, i) => (
        <Item key={i}>{title}</Item>
      ))}
    </List>
    <Button className="button" to={getRelativeURL(service.button)}>
      LEARN MORE
    </Button>
  </ServiceStyles>
);

export default Service;
