import React from "react";
import { graphql } from "gatsby";
import Layout from "@/Layout";
import FrontHero from "Partials/FrontHero";
import Portfolio from "Partials/Portfolio";
import Results from "Partials/Results";
import Reviews from "Partials/Reviews";
import Services from "Partials/Services";
import { ParallaxProvider } from "react-scroll-parallax";
const IndexPage = ({ data, location }) => {
  return (
    <ParallaxProvider>
      <Layout seo={data.wpPage.seo} location={location} noHeader>
        <FrontHero />
        <Portfolio />
        <Results />
        <Reviews />
        <Services />
      </Layout>
    </ParallaxProvider>
  );
};

export const pageQuery = graphql`
  # The page query for the front page's information.
  query {
    wpPage(isFrontPage: { eq: true }) {
      title
      ...SEO
    }
  }

  fragment Image on WpMediaItem {
    localFile {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 90)
      }
    }
    altText
  }

  # Fragment for Yoast's SEO data on pages
  fragment SEO on WpPage {
    seo {
      metaDesc
      metaKeywords
      metaRobotsNofollow
      metaRobotsNoindex
      opengraphAuthor
      opengraphDescription
      opengraphPublisher
      opengraphSiteName
      opengraphTitle
      opengraphType
      opengraphUrl
      opengraphImage {
        sourceUrl
      }
      schema {
        raw
      }
      title
      twitterDescription
      twitterTitle
      twitterImage {
        sourceUrl
      }
    }
  }

  # Fragment for Yoast's SEO data on posts
  fragment PostSEO on WpPost {
    seo {
      metaDesc
      metaKeywords
      metaRobotsNofollow
      metaRobotsNoindex
      opengraphAuthor
      opengraphDescription
      opengraphPublisher
      opengraphSiteName
      opengraphTitle
      opengraphType
      opengraphUrl
      opengraphImage {
        sourceUrl
      }
      schema {
        raw
      }
      title
      twitterDescription
      twitterTitle
      twitterImage {
        sourceUrl
      }
    }
  }
`;

export default IndexPage;
