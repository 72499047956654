import tw, { styled } from "twin.macro";

export const Section = styled.section`
  ${tw`flex flex-col items-center justify-center w-full px-4 py-12 text-center bg-white md:py-24 sm:px-8 xl:px-32 font-mont`}
`;

export const Content = styled.div`
  ${tw`flex flex-col w-full lg:flex-row`}
`;

export const Left = styled.ul`
  ${tw`flex flex-col w-full lg:(w-1/2 pr-8) -my-2 list-none`}
`;

export const Right = styled.div`
  ${tw`flex items-center justify-center mt-8 w-full lg:(w-1/2 mt-0 px-8)`}

  .graphic {
    max-width: 800px;

    @media (min-width: 1024px) {
      transform: translateY(-10%);
    }
  }
`;

export const Result = styled.li`
  ${tw`py-2`}
`;
