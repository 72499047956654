import React from "react";
import { graphql, StaticQuery } from "gatsby";
import { Section, Content, Left, Result, Right } from "./styles";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Title from "@/Title";
import Statistic from "@/Statistic";

const Results = () => (
  <StaticQuery
    query={graphql`
      query {
        wp {
          acfOptionsResults {
            results {
              supertext: resultsSupertext
              title: resultsTitle
              results {
                title
                description
              }
              graphic {
                ...Image
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const { results } = data.wp.acfOptionsResults;
      return (
        <Section>
          <Title title={results.title} supertext={results.supertext} hc />
          <Content>
            <Left>
              {results.results.map((result) => (
                <Result>
                  <Statistic {...result} />
                </Result>
              ))}
            </Left>
            <Right>
              <GatsbyImage
                image={getImage(results.graphic.localFile)}
                alt={results.graphic.altText}
                className="graphic"
              />
            </Right>
          </Content>
        </Section>
      );
    }}
  />
);

export default Results;
