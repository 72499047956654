import tw, { styled, css } from "twin.macro";

export const Section = styled.section`
  ${tw`flex w-full px-32 pb-16 md:pb-24`}

  @media (max-width: 1280px) {
    ${tw`px-4`}
  }
  @media (max-width: 860px) {
    ${tw`flex-col`}

    .button {
      ${tw`hidden`}
    }
  }
`;

export const Left = styled.div`
  ${tw`flex flex-col items-center justify-start w-7/12 pt-24 pr-12`}

  h2 {
    ${tw`mt-4 mb-6`}
  }

  article {
    ${tw`mb-16`}
  }

  @media (max-width: 860px) {
    ${tw`w-full pr-0`}
  }
`;

export const Right = styled.div`
  ${tw`flex flex-col w-1/2 pl-12 mt-12 lg:-my-8`}
  max-width: 600px;

  @media (max-width: 860px) {
    ${tw`w-full pl-0`}
    max-width: 100%;
  }
`;
