import React, { useEffect, useRef, useState } from "react";
import tw, { styled, css } from "twin.macro";
import { delay } from "#/functions";
import charming from "charming";
import anime from "animejs";

const IntroSequenceStyles = styled.div`
  ${tw`relative flex items-center justify-center w-full h-full`}
  width: 400%;
`;

const Title = styled.h2(({ current }) => [
  tw`relative flex flex-wrap items-center justify-center h-full -mx-3 text-2xl font-light tracking-widest text-center text-transparent uppercase whitespace-nowrap md:text-3xl lg:text-4xl xl:text-5xl font-mont`,
  !current && tw`hidden`,
  css`
    width: 400%;
    .break {
      @media (max-width: 1024px) {
        flex-basis: 100%;
        height: 0;
      }
    }
    .bold {
      ${tw`font-bold`}
    }
    & > span {
      ${tw`flex mx-2`}
    }
    & > span > span {
      display: inline-block;
      perspective: 1000px;
      transform-origin: 50% 50%;
    }

    & > span > span > span {
      transform: translate3d(0, 0, 0);
      transform-style: preserve-3d;
      color: rgba(0, 0, 0, 0);
    }
  `,
]);

const IntroSequence = ({ sequence, duration, onComplete, ...rest }) => {
  const sequenceRef = useRef(null);
  const [current, setCurrent] = useState(0);
  const totalDuration = duration * 1000;
  sequence = sequence.map(({ text, boldedWords }) => {
    return {
      text: text,
      boldedWords:
        boldedWords === null ? null : boldedWords.map(({ word }) => word),
    };
  });

  const animation = (titles, i) => {
    const timeline = anime
      .timeline({
        targets: titles[i].querySelectorAll("span > span > span"),
        easing: "easeInOutQuad",
      })
      .add({
        rotateY: ["-90deg", "0deg"],
        letterSpacing: ["80px", "14px"],
        opacity: [0.2, 0.8],
        textShadow: ["0 0 50px #fff;", "0 0 1px #fff"],
        duration: totalDuration * 0.5,
      });

    if (i < sequence.length - 1)
      timeline.add({
        textShadow: ["0 0 1px #fff;", "0 0 50px #fff;"],
        opacity: 0,
        duration: totalDuration * 0.15,
        translateZ: [100, 130],
        delay: totalDuration * 0.35,
        complete: () => {
          setCurrent(i + 1);
          animation(titles, i + 1);
        },
      });
    else if (typeof onComplete === "function")
      timeline.add({ duration: 0, complete: onComplete });
  };

  useEffect(async () => {
    const titles = sequenceRef.current.querySelectorAll(".sequence");
    animation(titles, 0);
  }, []);

  let wordNum = 0;
  return (
    <IntroSequenceStyles {...rest} ref={sequenceRef}>
      {sequence.map(({ text }, index) => (
        <Title key={index} className="sequence" current={index === current}>
          {text.split(/(\s+)/).map((word, i) => {
            if (word.trim()) {
              console.log(word);
              wordNum++;
            } else if (word.length > 6) {
              wordNum = 0;
            }
            return (
              <>
                <span
                  className={
                    sequence[index].boldedWords &&
                    sequence[index].boldedWords.includes(word)
                      ? `word-${i} bold`
                      : `word-${i}`
                  }
                  key={i}
                >
                  {word.split("").map((letter, j) => (
                    <span className={`char-${j}`} key={j}>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: letter !== undefined ? letter : "&nbsp;",
                        }}
                      />
                    </span>
                  ))}
                </span>
                {word.length > 6 && <span className="break"></span>}
                {wordNum % 2 === 0 && wordNum > 0 && (
                  <span className="break"></span>
                )}
              </>
            );
          })}
        </Title>
      ))}
    </IntroSequenceStyles>
  );
};

export default IntroSequence;
