import React from "react";
import { graphql, StaticQuery } from "gatsby";
import { Section } from "./styles";
import Gallery from "@/ParallaxGallery";
import Title from "@/Title";
import "twin.macro";

const Portfolio = () => (
  <StaticQuery
    query={graphql`
      query {
        wp(
          acfOptionsPortfolio: {
            portfolio: {
              gallery: { elemMatch: { fileSize: { lt: 15728640 } } }
            }
          }
        ) {
          acfOptionsPortfolio {
            portfolio {
              supertext
              title
              gallery {
                ...Image
              }
              parallaxScrollSpeed
            }
          }
        }
      }
    `}
    render={(data) => {
      const { portfolio } = data.wp.acfOptionsPortfolio;
      const imageData = portfolio.gallery.map(({ localFile }) => localFile);
      return (
        <Section>
          <Title {...portfolio} tw="mb-16 px-4 md:px-32" />
          <Gallery images={imageData} speed={portfolio.parallaxScrollSpeed} />
        </Section>
      );
    }}
  />
);

export default Portfolio;
