import React from "react";
import tw, { styled } from "twin.macro";

const Wrapper = tw.div`flex flex-col items-center justify-center text-center font-mont w-full bg-background text-white py-12 px-8`;

const Title = tw.p`text-primary text-2xl md:text-4xl font-bold mb-4`;
const Description = styled.p`
  ${tw`text-base md:text-xl`}
  max-width: 400px;
`;

const Statistic = ({ title, description, ...rest }) => (
  <Wrapper {...rest}>
    <Title>{title}</Title>
    <Description>{description}</Description>
  </Wrapper>
);

export default Statistic;
