import tw, { styled, css } from "twin.macro";

export const Section = tw.section`flex flex-col items-center justify-center w-full text-center`;

export const Services = styled.ol`
  ${tw`relative flex flex-wrap w-full`}
  box-shadow: 0 0 57px rgba(0, 0, 0, 0.16);
`;

export const Item = tw.li`flex h-full w-full sm:w-1/2 xl:w-1/4`;
